import React, { useContext, useEffect, useState } from 'react';
import {
  Paper, Button, useMediaQuery, createTheme, Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { Link, navigate } from 'gatsby';
import { themeStyles } from '../../../styles';
import { useStyles } from '../styles';
import ThemeContext from '../../../utils/theme-context';

const CarouselCard = ({ item }) => {
  const partnerTheme = useContext(ThemeContext);
  const theme = createTheme({});
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();
  const globalthemClasses = themeStyles(partnerTheme);
  const [ast, setAst] = useState(false);
  const [flowOrder, setFlowOrder] = useState('');

  useEffect(() => {
    setAst(JSON.parse(localStorage.getItem('ast')));
    setFlowOrder(JSON.parse(localStorage.getItem('flowOrder')));
  });

  let showModal;
  if (ast) {
    showModal = '/ast';
  } else {
    switch (flowOrder) {
      case 'Selling Only':
        showModal = '/customer-info';
        break;
      case 'Buying Only':
        showModal = '/customer-info';
        break;
      case 'Buying, Selling':
        showModal = '/service-selection';
        break;
      case 'Selling, Buying':
        showModal = '/service-selection';
        break;
      default:
        showModal = '/#embedded-video';
    }
  }

  const navigateToPage = (path) => [
    navigate(path)
  ];

  return (
    <Paper tabIndex="0">
      {!isMobile ? (
        <div
          style={{
            backgroundImage: `url(${item.url})`,
            justifyContent: `${item.align}`
          }}
          className={classes.imageAlignment}
        >
          <div
            className={
              item.cardStyleRight
                ? `${classes.cardStyleRight}`
                : `${classes.cardStyle}`
            }
          >
            <Typography variant="body2" className={classes.name}>{item.eyebrowText}</Typography>
            <Typography variant="subtitle2" component="h2" className={classes.heading}>{item.name}</Typography>
            <Typography variant="body2" className={classes.description}>{item.description}</Typography>
            { item.textData.primaryCallToAction
              && item.textData.primaryCallToAction.displayPrimaryCta && (
              <Link to={showModal} tabindex="-1">
                <Button
                  onClick={() => navigateToPage(showModal)}
                  variant="contained"
                  color={item.color}
                  size={item.size}
                  className={globalthemClasses.button}
                >
                  {item.textData.primaryCallToAction.labelForPrimaryCta}
                </Button>
              </Link>
            )}
          </div>
        </div>
      ) : (
        <section className={classes.noHeaderContentArea}>
          <img
            src={item.url}
            alt={item?.imageAltText}
            className={classes.mobileImageAlignment}
          />

          <div
            className={
              item.cardStyleRight
                ? `${classes.cardStyleRight}`
                : `${classes.cardStyle}`
            }
          >
            <Typography variant="body2" className={classes.name}>{item.eyebrowText}</Typography>
            <Typography variant="subtitle2" component="h2" className={classes.heading}>{item.name}</Typography>
            <Typography variant="body2" className={classes.description}>{item.description}</Typography>
            { item.textData.primaryCallToAction
              && item.textData.primaryCallToAction.displayPrimaryCta && (
              <Link to={showModal} tabindex="-1">
                <Button
                  onClick={() => navigateToPage(showModal)}
                  variant="contained"
                  color={item.color}
                  size={item.size}
                  className={clsx(globalthemClasses.button, classes.carouselBtn)}
                >
                  {item.textData.primaryCallToAction.labelForPrimaryCta}
                </Button>
              </Link>
            )}
          </div>
        </section>
      )}
    </Paper>
  );
};

export default CarouselCard;
