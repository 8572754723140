/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable import/order */
import React, { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { navigate } from 'gatsby-link';
import { useStyles } from './styles';
import { themeStyles } from '../../styles';
import '../../styles.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ThemeContext from '../../utils/theme-context';
import superScriptFormat from '../../utils/superscript-check';

export default function CashBackReward({ data }) {
  const classes = useStyles(data);
  const partnerTheme = useContext(ThemeContext);
  const [ast, setAst] = useState(false);
  const [flowOrder, setFlowOrder] = useState('');
  const globalthemClasses = themeStyles(partnerTheme);

  useEffect(() => {
    setAst(JSON.parse(localStorage.getItem('ast')));
    setFlowOrder(JSON.parse(localStorage.getItem('flowOrder')));
  });

  const variantVal = partnerTheme?.buttonStyle?.buttonType === 'contained' || partnerTheme?.buttonStyle?.buttonType === 'outlined' || partnerTheme?.buttonStyle?.buttonType === 'text' ? partnerTheme?.buttonStyle?.buttonType : 'contained';
  let btnClassName;
  if (variantVal === 'contained') {
    btnClassName = globalthemClasses.containedBtn;
  } else if (variantVal === 'outlined') {
    btnClassName = globalthemClasses.outlinedBtn;
  } else {
    btnClassName = globalthemClasses.textBtn;
  }

  let showModal;
  if (ast) {
    showModal = '/ast';
  } else {
    switch (flowOrder) {
      case 'Selling Only':
        showModal = '/customer-info';
        break;
      case 'Buying Only':
        showModal = '/customer-info';
        break;
      case 'Buying, Selling':
        showModal = '/service-selection';
        break;
      case 'Selling, Buying':
        showModal = '/service-selection';
        break;
      default:
        showModal = '/#embedded-video';
    }
  }

  const navigateToPage = (path) => [
    navigate(path)
  ];

  return (
    <div id="cmp-cash-back-reward" className={clsx(classes.root, globalthemClasses.cashbackRewardBackground)}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.gridWrapper}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {data?.title && (<Typography id="cash-back-reward-h2-text" variant="h2">{superScriptFormat(data.title)}</Typography>)}
        </Grid>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid container item spacing={2} direction="column" justifyContent="center" alignItems="center">
            <Button
              component={Link}
              id="cash-back-reward-signup-btn"
              size="large"
              onClick={() => navigateToPage(showModal)}
              variant={variantVal}
              className={clsx(`${btnClassName}`, classes.signupButton)}
              to={showModal}
              role="link"
            >
              {data.button.link.label}
            </Button>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {data.subText.raw
            && (
            <Typography id="cash-back-paragraph-text" variant="body2" className={classes.link}>
              {documentToReactComponents(JSON.parse(data.subText.raw))}
            </Typography>
            )}
          </Grid>
        </Grid>

      </Grid>
    </div>
  );
}
