/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import React, { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { navigate } from 'gatsby-link';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { themeStyles } from '../../styles';
import { useStyles } from './styles';
import ThemeContext from '../../utils/theme-context';
import superScriptFormat from '../../utils/superscript-check';

const alignItemsMap = {
  Top: 'flex-start',
  Center: 'center',
  Bottom: 'flex-end',
  Stretch: 'stretch'
};

const justifyContentMap = {
  Start: 'flex-start',
  Center: 'center',
  End: 'flex-end'
};

export default function Banner({ data }) {
  const partnerTheme = useContext(ThemeContext);
  const [ast, setAst] = useState(false);
  const [flowOrder, setFlowOrder] = useState('');
  let formPath;
  const bgImg = [{
    bannerImg1: data.bannerImage.file.url,
    justifyC: justifyContentMap[data.justifyContent],
    alignI: alignItemsMap[data.alignItems],
    textAlign: data.alignText
  }];

  useEffect(() => {
    setAst(JSON.parse(localStorage.getItem('ast')));
    setFlowOrder(JSON.parse(localStorage.getItem('flowOrder')));
  });

  const classes = useStyles({ bgImg });
  const themeClass = themeStyles(partnerTheme);

  if (ast) {
    formPath = '/ast';
  } else {
    switch (flowOrder) {
      case 'Selling Only':
        formPath = '/customer-info';
        break;
      case 'Buying Only':
        formPath = '/customer-info';
        break;
      case 'Buying, Selling':
        formPath = '/service-selection';
        break;
      case 'Selling, Buying':
        formPath = '/service-selection';
        break;
      default:
        formPath = '/#embedded-video';
    }
  }

  // const variantVal = partnerTheme?.buttonStyle?.buttonType === 'contained' || partnerTheme?.buttonStyle?.buttonType === 'outlined' || partnerTheme?.buttonStyle?.buttonType === 'text' ? partnerTheme?.buttonStyle?.buttonType : 'contained';
  // let btnClassName;
  // if (variantVal === 'contained') {
  //   btnClassName = themeClass.containedBtn;
  // } else if (variantVal === 'outlined') {
  //   btnClassName = themeClass.outlinedBtn;
  // } else {
  //   btnClassName = themeClass.textBtn;
  // }

  return (

    <div id="cmp-banner-area" className={classes.root}>
      <img
        id="banner-background-img"
        src={data.bannerImage.file.url}
        alt={data?.bannerImage?.description}
        className={classes.bannerImage}
      />
      <div className={classes.section}>
        <Grid
          container
          direction="column"
          className={classes.gridWidth}
          md={12}
        >
          <Grid
            container
            direction="column"
            className={classes.columnGrid}
          >
            { data.bannerLogo && data.bannerLogoPosition === true
              && <img src={data.bannerLogo.file.url} alt={data?.bannerLogo?.description} />}
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.columnGrid}
          >
            { data.bannerLogo && data.bannerLogoPosition == null
            && <img src={data.bannerLogo.file.url} alt={data?.bannerLogo?.description} />}
          </Grid>
          <Grid
            id="banner-text"
            container
            direction="column"
          >
            <Typography id="banner-h2-heading" variant="h1" component="h2">{superScriptFormat(data.title)}</Typography>
            {data.bannerDescriptionRichText && data.displaySubText === true && (
            <Typography id="banner-paragraph-subText" variant="subtitle1" component="p">
              {data?.bannerDescriptionRichText?.raw && documentToReactComponents(JSON.parse(data?.bannerDescriptionRichText?.raw))}
            </Typography>
            )}
            {data.bannerDescriptionRichText && data.displaySubText == null && (
            <Typography id="banner-paragraph" variant="subtitle1" component="p">
              {data?.bannerDescriptionRichText?.raw && documentToReactComponents(JSON.parse(data?.bannerDescriptionRichText?.raw))}
            </Typography>
            )}
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.columnGrid}
          >
            { data.bannerLogo && data.bannerLogoPosition === false
           && <img src={data.bannerLogo.file.url} alt={data?.bannerLogo?.description} />}
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.columnGrid}
          >
            {data.button.displayPrimaryCta && (
              <Button
                component={Link}
                id="banner-signup-btn"
                onClick={() => navigate(formPath)}
                variant="contained"
                className={clsx(themeClass.containedBtn, classes.bannerBtn)}
                size="large"
                to={formPath}
                role="link"
              >
                {data.button.labelForPrimaryCta}
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

//          <Grid
//           container
//           direction="column"
//           className={classes.gridWidth}
//           md={12}
//         >
//           <Grid
//             container
//             direction="column"
//             className={classes.columnGrid}
//           >
//             { data.bannerLogo && data.bannerLogoPosition==null
//               &&
//                <img src={data.bannerLogo.file.url} alt={data?.bannerLogo?.description} />}
//           </Grid>
//           <Grid
//             container
//             direction="column"
//           >
//             <Typography variant="h1" component="h2">{data.title}</Typography>
//             {data.bannerDescriptionRichText  && data.displaySubText==true && (
//             <Typography variant="subtitle1" component="p">
//               {data?.bannerDescriptionRichText?.raw && documentToReactComponents(JSON.parse(data?.bannerDescriptionRichText?.raw))}
//             </Typography>
//             )}
//           </Grid>
//           <Grid
//             container
//             direction="column"
//             className={classes.columnGrid}
//           >
//             { data.bannerLogo && data.bannerLogoPosition==false
//               && <img src={data.bannerLogo.file.url} alt={data?.bannerLogo?.description} />}
//           </Grid>
//           <Grid
//             container
//             direction="column"
//             className={classes.columnGrid}
//           >
//             {data.button.displayPrimaryCta && (
//             <Button
//               onClick={() => navigate(formPath)}
//               variant="contained"
//               className={clsx(themeClass.button)}
//             >
//               {data.button.labelForPrimaryCta}
//             </Button>
//             )}
//           </Grid>
//         </Grid>

//         {data?.bannerLogoPosition && (
//         <Grid
//           container
//           direction="column"
//           className={classes.gridWidth}
//           md={12}
//         >
//           <Grid
//             container
//             direction="column"
//           >
//             <Typography variant="h1" component="h2">{data.title}</Typography>
//             {data.bannerDescriptionRichText  && data.displaySubText==null && (
//             <Typography variant="subtitle1" component="p">
//               {data?.bannerDescriptionRichText?.raw && documentToReactComponents(JSON.parse(data?.bannerDescriptionRichText?.raw))}
//             </Typography>
//             )}

//           </Grid>
//           <Grid
//             container
//             direction="column"
//             className={classes.columnGrid}
//           >
//             { data.bannerLogo && data.bannerLogoPosition==false
//               && <img src={data.bannerLogo.file.url} alt={data?.bannerLogo?.description} />}
//           </Grid>

//           <Grid
//             container
//             direction="column"
//             className={classes.columnGrid}
//           >
//             {data.button.displayPrimaryCta && (
//             <Button
//               onClick={() => navigate(formPath)}
//               variant="contained"
//               className={clsx(themeClass.button,classes.bannerBtn)}
//               size="large"
//             >
//               {data.button.labelForPrimaryCta}
//               </Button>
//             )}
//           </Grid>
//         </Grid>
//         )}
//       </div>
//     </div>
//   );
// }
